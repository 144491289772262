import React from 'react';
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Dots from '../images/team/dots.svg'
import EdwinHo from '../images/team/Edwin-Ho.png'
import MasonPeck from '../images/team/Mason-Peck.png'
import RobertFarting from '../images/team/Robert-Farting.png'
import Betty from '../images/team/Betty.png'
import Joyce from '../images/team/Joyce.png'
import Dev from '../images/team/Dev.png'
import Michael from '../images/team/Michael.png'
import Sapphire from '../images/team/Sapphire.png'
import Brian from '../images/team/Brian.png'
import Puneet from '../images/team/Puneet.png'
import Bhawin from '../images/team/Bhawin.png'
import AnkurBorah from '../images/team/Ankur-Borah.png'
import Ferdi from '../images/team/Ferdi.png'
import Dagz from '../images/team/Dagz.png'
import AK from '../images/team/AK.png'
import Franklin from '../images/team/Franklin.png'
import Peter from '../images/team/Peter.png'

function team() {  
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Team" />
            <Container className="py-5">
                <Row className="justify-content-center text-center mb-5">
                    <Col lg={12} className="mb-3">
                        <div className="section-title">
                            <div>Team</div>
                            <h3>Team</h3>
                        </div>
                    </Col>

                    <Col lg={8} md={10} className="mb-md-5">
                        <p>Meet the Team behind HedgePay. 20 people in 5 departments are dedicated to guide all of our holders into their individual cryptocurrency rewards. Development, operations, communication, sales and partnership, we are working passionately in all our departments to achieve that goal.</p>
                    </Col>
                </Row>
                
                <div className="team-group">
                    <Row className='justify-content-center'>
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={EdwinHo} alt="Edwin Ho" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Edwin Ho</h3>
                                        <h5><span>Founder & Director</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={MasonPeck} alt="Mason Peck" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Mason Peck</h3>
                                        <h5><span>Founder & CEO</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={RobertFarting} alt="Robert Farting" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Robert Farting</h3>
                                        <h5><span>CTO</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Betty} alt="Betty" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Betty</h3>
                                        <h5><span>Hedge Liquidity Manager</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Joyce} alt="Joyce" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Joyce</h3>
                                        <h5><span>Account Manager</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        {/* <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Eugene} alt="Eugene" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Eugene</h3>
                                        <h5><span>Executive Assistant</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                     */}
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Dev} alt="Dev" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Dev</h3>
                                        <h5><span>Lead Developer</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.9902 0.300782C4.91808 0.298327 0 5.21395 0 11.2811C0 16.0789 3.07656 20.1573 7.36116 21.655C7.93817 21.7999 7.84978 21.3898 7.84978 21.1099V19.207C4.51786 19.5974 4.38281 17.3925 4.15938 17.0242C3.70759 16.2532 2.63951 16.0568 2.95871 15.6885C3.71741 15.2981 4.49085 15.7867 5.38705 17.1102C6.03527 18.0702 7.29978 17.9081 7.94063 17.7486C8.08058 17.1715 8.38014 16.6559 8.79264 16.2557C5.3404 15.6369 3.90156 13.5302 3.90156 11.0258C3.90156 9.81038 4.30179 8.69319 5.0875 7.79208C4.58661 6.30659 5.13415 5.03471 5.20781 4.84565C6.63438 4.71797 8.11741 5.86708 8.23281 5.95793C9.04308 5.7394 9.96875 5.624 11.0049 5.624C12.046 5.624 12.9741 5.74431 13.7917 5.96529C14.0692 5.75413 15.4442 4.76708 16.7701 4.88739C16.8413 5.07645 17.3766 6.31886 16.9051 7.78471C17.7007 8.68828 18.1058 9.81529 18.1058 11.0331C18.1058 13.5425 16.6571 15.6517 13.1951 16.2606C13.4916 16.5522 13.7271 16.9 13.8877 17.2836C14.0483 17.6673 14.1309 18.0791 14.1306 18.495V21.2573C14.1502 21.4782 14.1306 21.6968 14.4989 21.6968C18.8473 20.2309 21.9779 16.1231 21.9779 11.2836C21.9779 5.21395 17.0574 0.300782 10.9902 0.300782V0.300782Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.8963 11.1267L18.9158 0.987597C18.7299 0.429785 18.2842 0.0551758 17.6881 0.0551758C17.092 0.0551758 16.608 0.391504 16.4221 0.949316L14.4479 6.83916H7.55997L5.58575 0.952051C5.39981 0.394238 4.91583 0.0579099 4.31973 0.0579099C3.72364 0.0579099 3.23965 0.429785 3.092 0.990332L0.114263 11.1267C0.002154 11.4985 0.152544 11.9087 0.450591 12.1329L10.9889 20.0353L21.5627 12.1329C21.858 11.9114 22.0084 11.5013 21.8963 11.1267Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Michael} alt="Michael" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Michael</h3>
                                        <h5><span>Head of Operation</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Sapphire} alt="Sapphire" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Sapphire</h3>
                                        <h5><span>Head of Communication</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Brian} alt="Brian" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Brian</h3>
                                        <h5><span>Frontend Dev</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.9902 0.300782C4.91808 0.298327 0 5.21395 0 11.2811C0 16.0789 3.07656 20.1573 7.36116 21.655C7.93817 21.7999 7.84978 21.3898 7.84978 21.1099V19.207C4.51786 19.5974 4.38281 17.3925 4.15938 17.0242C3.70759 16.2532 2.63951 16.0568 2.95871 15.6885C3.71741 15.2981 4.49085 15.7867 5.38705 17.1102C6.03527 18.0702 7.29978 17.9081 7.94063 17.7486C8.08058 17.1715 8.38014 16.6559 8.79264 16.2557C5.3404 15.6369 3.90156 13.5302 3.90156 11.0258C3.90156 9.81038 4.30179 8.69319 5.0875 7.79208C4.58661 6.30659 5.13415 5.03471 5.20781 4.84565C6.63438 4.71797 8.11741 5.86708 8.23281 5.95793C9.04308 5.7394 9.96875 5.624 11.0049 5.624C12.046 5.624 12.9741 5.74431 13.7917 5.96529C14.0692 5.75413 15.4442 4.76708 16.7701 4.88739C16.8413 5.07645 17.3766 6.31886 16.9051 7.78471C17.7007 8.68828 18.1058 9.81529 18.1058 11.0331C18.1058 13.5425 16.6571 15.6517 13.1951 16.2606C13.4916 16.5522 13.7271 16.9 13.8877 17.2836C14.0483 17.6673 14.1309 18.0791 14.1306 18.495V21.2573C14.1502 21.4782 14.1306 21.6968 14.4989 21.6968C18.8473 20.2309 21.9779 16.1231 21.9779 11.2836C21.9779 5.21395 17.0574 0.300782 10.9902 0.300782V0.300782Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.8963 11.1267L18.9158 0.987597C18.7299 0.429785 18.2842 0.0551758 17.6881 0.0551758C17.092 0.0551758 16.608 0.391504 16.4221 0.949316L14.4479 6.83916H7.55997L5.58575 0.952051C5.39981 0.394238 4.91583 0.0579099 4.31973 0.0579099C3.72364 0.0579099 3.23965 0.429785 3.092 0.990332L0.114263 11.1267C0.002154 11.4985 0.152544 11.9087 0.450591 12.1329L10.9889 20.0353L21.5627 12.1329C21.858 11.9114 22.0084 11.5013 21.8963 11.1267Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Puneet} alt="Puneet" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Puneet</h3>
                                        <h5><span>Frontend Dev</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.9902 0.300782C4.91808 0.298327 0 5.21395 0 11.2811C0 16.0789 3.07656 20.1573 7.36116 21.655C7.93817 21.7999 7.84978 21.3898 7.84978 21.1099V19.207C4.51786 19.5974 4.38281 17.3925 4.15938 17.0242C3.70759 16.2532 2.63951 16.0568 2.95871 15.6885C3.71741 15.2981 4.49085 15.7867 5.38705 17.1102C6.03527 18.0702 7.29978 17.9081 7.94063 17.7486C8.08058 17.1715 8.38014 16.6559 8.79264 16.2557C5.3404 15.6369 3.90156 13.5302 3.90156 11.0258C3.90156 9.81038 4.30179 8.69319 5.0875 7.79208C4.58661 6.30659 5.13415 5.03471 5.20781 4.84565C6.63438 4.71797 8.11741 5.86708 8.23281 5.95793C9.04308 5.7394 9.96875 5.624 11.0049 5.624C12.046 5.624 12.9741 5.74431 13.7917 5.96529C14.0692 5.75413 15.4442 4.76708 16.7701 4.88739C16.8413 5.07645 17.3766 6.31886 16.9051 7.78471C17.7007 8.68828 18.1058 9.81529 18.1058 11.0331C18.1058 13.5425 16.6571 15.6517 13.1951 16.2606C13.4916 16.5522 13.7271 16.9 13.8877 17.2836C14.0483 17.6673 14.1309 18.0791 14.1306 18.495V21.2573C14.1502 21.4782 14.1306 21.6968 14.4989 21.6968C18.8473 20.2309 21.9779 16.1231 21.9779 11.2836C21.9779 5.21395 17.0574 0.300782 10.9902 0.300782V0.300782Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.8963 11.1267L18.9158 0.987597C18.7299 0.429785 18.2842 0.0551758 17.6881 0.0551758C17.092 0.0551758 16.608 0.391504 16.4221 0.949316L14.4479 6.83916H7.55997L5.58575 0.952051C5.39981 0.394238 4.91583 0.0579099 4.31973 0.0579099C3.72364 0.0579099 3.23965 0.429785 3.092 0.990332L0.114263 11.1267C0.002154 11.4985 0.152544 11.9087 0.450591 12.1329L10.9889 20.0353L21.5627 12.1329C21.858 11.9114 22.0084 11.5013 21.8963 11.1267Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Bhawin} alt="Bhawin" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Bhawin</h3>
                                        <h5><span>Backend Dev</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.9902 0.300782C4.91808 0.298327 0 5.21395 0 11.2811C0 16.0789 3.07656 20.1573 7.36116 21.655C7.93817 21.7999 7.84978 21.3898 7.84978 21.1099V19.207C4.51786 19.5974 4.38281 17.3925 4.15938 17.0242C3.70759 16.2532 2.63951 16.0568 2.95871 15.6885C3.71741 15.2981 4.49085 15.7867 5.38705 17.1102C6.03527 18.0702 7.29978 17.9081 7.94063 17.7486C8.08058 17.1715 8.38014 16.6559 8.79264 16.2557C5.3404 15.6369 3.90156 13.5302 3.90156 11.0258C3.90156 9.81038 4.30179 8.69319 5.0875 7.79208C4.58661 6.30659 5.13415 5.03471 5.20781 4.84565C6.63438 4.71797 8.11741 5.86708 8.23281 5.95793C9.04308 5.7394 9.96875 5.624 11.0049 5.624C12.046 5.624 12.9741 5.74431 13.7917 5.96529C14.0692 5.75413 15.4442 4.76708 16.7701 4.88739C16.8413 5.07645 17.3766 6.31886 16.9051 7.78471C17.7007 8.68828 18.1058 9.81529 18.1058 11.0331C18.1058 13.5425 16.6571 15.6517 13.1951 16.2606C13.4916 16.5522 13.7271 16.9 13.8877 17.2836C14.0483 17.6673 14.1309 18.0791 14.1306 18.495V21.2573C14.1502 21.4782 14.1306 21.6968 14.4989 21.6968C18.8473 20.2309 21.9779 16.1231 21.9779 11.2836C21.9779 5.21395 17.0574 0.300782 10.9902 0.300782V0.300782Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.8963 11.1267L18.9158 0.987597C18.7299 0.429785 18.2842 0.0551758 17.6881 0.0551758C17.092 0.0551758 16.608 0.391504 16.4221 0.949316L14.4479 6.83916H7.55997L5.58575 0.952051C5.39981 0.394238 4.91583 0.0579099 4.31973 0.0579099C3.72364 0.0579099 3.23965 0.429785 3.092 0.990332L0.114263 11.1267C0.002154 11.4985 0.152544 11.9087 0.450591 12.1329L10.9889 20.0353L21.5627 12.1329C21.858 11.9114 22.0084 11.5013 21.8963 11.1267Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                                        
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={AnkurBorah} alt="Ankur Borah" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Ankur Borah</h3>
                                        <h5><span>Operations</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Ferdi} alt="Ferdi" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Ferdi</h3>
                                        <h5><span>Operations</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Dagz} alt="Dagz" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Dagz</h3>
                                        <h5><span>Operations</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={AK} alt="AK" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>AK</h3>
                                        <h5><span>Sales</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Franklin} alt="Franklin" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Franklin</h3>
                                        <h5><span>Communications</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                    
                        <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={Peter} alt="Peter" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Peter</h3>
                                        <h5><span>Communications</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                                        
                    </Row>
                </div>
            </Container>
        </Layout>
    );
}

export default team;
